import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import Login from "@/models/Login";
import LoginResponse from "@/models/LoginResponse";
import { ErrorResponse } from "@/models/ErrorResponse";

import Store from "@/store/index";

const BaseURL = process.env.VUE_APP_API_BASE_URL;
// const BaseURL = "http://localhost:8080/aries/api";
//"http://localhost:8080/aries/api";
//const BaseURL = "https://www.hgswp01.com/aries/api";
//const BaseURL = "https://shikoku-s-weborder.com/aries/api";

function getToken() {
  return Store.state.token;
}

function getManagerToken() {
  return Store.state.mtoken;
}

function createHttpClient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken()
    }
  });
}

function createDownloadExcelHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "application/zip"
    },
    responseType: "blob"
  });
}

function createDownloadCSVHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "text/csv"
    },
    responseType: "blob"
  });
}

function getUrl(
  baseUrl: string,
  params: string | string[] | undefined
): string {
  // const param = params
  //   ? params instanceof String
  //     ? params
  //     : (params as string[]).join("/")
  //   : "";
  const param = params ? params : "";
  const url = param
    ? baseUrl + (baseUrl.match("^.*/$") ? "" : "?") + param
    : baseUrl + (baseUrl.match("^.*/$") ? "" : "/");
  return url;
}

const batchEntry = {
  bulkInsert: "/batch-entry/bulk-insert",
  bulkUpdate: "/batch-entry/bulk-update",
  get: "/batch-entry/get",
  bulkDelete: "/batch-entry/bulk-delete",
  getOrderMeisai: "/batch-entry/get/order-meisai",
  getReceivedOrderMeisai: "/batch-entry/get/received-order-meisai",
  getTokuiGroup: "/batch-entry/get/tokui-group",
  getFormat: "/batch-entry/get/format"
} as const;

const Paths = {
  atuhShozoku: "/auth/shozoku",
  authManager: "/auth/manager",
  authTemporary: "/auth/temporary",
  attendance: "/attendance",
  attendanceFix: "/attendance/fix",
  attendanceStart: "/attendance/start",
  attendanceEnd: "/attendance/end",
  attendanceCalcTime: "/attendance/calc",
  attendanceCalc: "/attendance/salarycalc",
  attendanceSalaryFix: "/attendance/salaryfix",
  attendanceMultiSalaryCalc: "/attendance/multisalarycalc",
  attendanceSalaryConfirm: "/attendance/salary-confirm",
  attendanceBulk: "/attendance/bulk",
  shain1: "/daijin/shain1",
  shozoku: "/daijin/shozoku",
  shiftUpsert: "shift/upsert",
  shiftpattern: "/shiftpattern",
  shiftpatternPulldown: "/shiftpattern/pull",
  workClassification: "/workc",
  shift: "/shift",
  shiftExcel: "/shift/excel",
  authinfoTemporary: "/authinfo/temporary",
  codeGroup: "/codegroup",
  codeGroupPull: "/codegroup/pull",
  forCalc: "/for-calc",
  forCalcWelfare: "/for-calc/welfare",
  codeMaster: "/code-master",
  codeMasterPulldown: "/code-master/pulldown",
  shozokuQr: "/daijin/shozoku/qr",
  shainQr: "/daijin/shain1/qr",
  shozokuExp: "/shozoku-exp",
  shainExp: "/shain-exp",
  welfareAttendanceImport: "welfare/import",
  authinfo: "/authinfo",
  authinfoBulk: "/authinfo/bulk",
  dailyEvent: "/daily-event",
  shiftOrder: "/shift-order",
  shozokuFilter: "/daijin/shozoku/filter",
  shainFilter: "/shain/filter",
  shiftAttendance: "/shift-attendance",
  meetingAdd: "/welfare/meeting",
  tmpDistance: "/welfare/distance",
  relyToShain: "/rely-to-shain",
  relyToShozoku: "/rely-to-shozoku",
  attendanceCalcTimeFukushi: "/attendance/calcfukushi",
  attendanceCalcFukushi: "/attendance/salarycalcfukushi",
  attendanceMultiCalc: "/attendance/calc-multi",
  shainSorted: "/daijin/shain1/sorted",
  shiftPatternOrder: "/shiftpattern/order",
  authNomalUser: "/auth/user",
  attendanceStartSp: "/attendance/sp/start",
  attendanceEndSp: "/attendance/sp/end",
  trainingAdd: "/welfare/training",
  attendanceMultiCalcFukushi: "/attendance/calc-multi-fukushi",
  attendanceMultiSaralyCalcFukushi: "/attendance/multisalarycalc-fukushi",
  approvalList: "/approval",
  approvalStatus: "/approval/status",
  shozokuFilterForCalc: "/daijin/shozoku/filter/for-calc",
  shozokuFilterForCalcWelfare: "/daijin/shozoku/filter/for-calc/welfare",
  shiftLock: "/shift-lock",
  transportationExpenses: "/transportation-expenses",
  package: "/package",
  hpFilter: "/hospital/hpfilter",
  hospitalfilter: "/hospital/hospitalfilter",
  shain: "/shain",
  shainTokui: "/shain/tokui",
  codeMasterPulldownAutho: "/code-master/pulldown-auth",
  hospital: "/hospital",
  scheduleMaintenance: "/scheduleMaintenance",
  schedule: "/schedule",
  schedulefix: "/schedule/fix",
  servicerecordMaintenance: "/servicerecordMaintenance",
  schedulefind: "/schedule/schedulefind",
  schedulebulkinsert: "/schedule/bulkinsert",
  scheduleSearch: "/schedule/search",
  exportjyutyu: "/csvexport",
  exportcheckjyutyu: "/csvexport/check/jyutyu",
  checkjyutyudata: "/csvexport/check/jyutyudata",
  shohin: "/shohin",
  tokuiGroup: "/tokuiGroup",
  tokuiGroupPullDown: "tokuiGroup/pulldown",
  groupPullDown: "tokuiGroup/pulldown/group",
  tokuiGroupId: "tokuiGroup/groupId",
  tanni: "/tanni",
  tanniMaxId: "/tanni/getMaxId",
  codeMasterPulldownCkbn: "/code-master/pulldown-ckbn",
  jyutyuFormat: "/jyutyu",
  jyutyuInput: "/jyutyuInput",
  jyutyuPulldown: "/jyutyu/pulldown",
  jyutyuDenpyoNo: "jyutyuInput/denpyoNo",
  tanto: "/tanto",
  santiPulldown: "/santi/pulldown",
  kikakuPulldown: "/kikaku/pulldown",
  tanniPulldown: "/tanni/pulldown",
  shohinNamePulldown: "/shohin/pulldown",
  tantoPulldown: "/tanto/pulldown",
  jyutyuTokuiPulldown: "/tokuiGroup/jyutyu/pulldown",
  batchEntry: batchEntry
};

export default Vue.extend({
  name: "Api",
  data() {
    return {
      Paths
    };
  },
  methods: {
    async $auth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr ? "1" : "0"
      };

      let path = "";
      switch (payload.selectTab) {
        case 0:
          path = Paths.atuhShozoku;
          break;
        case 1:
          path = Paths.authManager;
          break;
        default:
          path = Paths.authNomalUser;
          break;
      }

      return await this.$post(path, data);
    },
    async $tmpauth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr
      };

      return await this.$post(Paths.authTemporary, data);
    },
    async $verify(payload: Login): Promise<any> {
      console.log("auth", payload);

      const token = getToken();

      if (token) {
        Store.state.name = "ダミー";
        Store.state.login = true;
      }

      return !!token;
    },
    /**
     * Get Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $get<T = any>(
      path: string,
      params?: string | string[] | undefined,
      query?: any | undefined
    ): Promise<T> {
      try {
        const client = createHttpClient();
        const url = getUrl(path, params);

        const result = await client.get<T>(url, { params: query });
        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async $downloadexcel(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      try {
        const client = createDownloadExcelHttpCLient();
        const url = getUrl(path, params);

        const result = await client.get(url, { params: query });

        const error = result.data.error;
        if (error) {
          console.error(error);
          throw new Error(error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async $downloadcsv(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createDownloadCSVHttpCLient();

      const url = getUrl(path, params);

      const result = await client.get(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * POST Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $post<T = any>(
      path: string,
      data: any,
      params: string | string[] = ""
    ): Promise<T> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("POST: " + url + " &" + JSON.stringify(data));

      const result = await client.post<T>(url, data);

      if (ErrorResponse.isValid(result.data)) {
        console.error(result.data.error);
        throw new Error(result.data.error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * PUT Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $put(
      path: string,
      data: any,
      params: string | string[]
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("PUT: " + url + " &" + JSON.stringify(data));

      const result = await client.put(url, data);

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },

    /**
     * DELETE Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $del(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("DELETE: " + url + "?" + JSON.stringify(query));

      const result = await client.delete(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    }
  }
});
