export class ErrorResponse {
  error: APIError;

  constructor(props: APIError) {
    this.error = props;
  }

  static isValid = (arg: unknown): arg is ErrorResponse => {
    const valid = arg as ErrorResponse;
    return typeof valid?.error === "object";
  };
}
export type APIError = {
  errorCode: number;
  errorMessage: string;
};
